import React from "react";
import { FormLogIn } from "../../components/FormLogIn";
import "./style.css";

export const Website = () => {
  return (
    <div className="website">
      <div className="div-7">
        <div className="illustration-2">
          <div className="overlap-30">
            <img className="path-51" alt="Path" src="/img/path-1.svg" />
            <img className="path-52" alt="Path" src="/img/path-2.svg" />
            <img className="path-53" alt="Path" src="/img/path-3.svg" />
            <img className="oval-4" alt="Oval" src="/img/oval.svg" />
            <img className="oval-5" alt="Oval" src="/img/oval-1.svg" />
            <div className="group-19">
              <div className="overlap-group-9">
                <img className="path-54" alt="Path" src="/img/path-4.svg" />
                <img className="path-55" alt="Path" src="/img/path-5.svg" />
                <img className="path-56" alt="Path" src="/img/path-6.svg" />
                <img className="path-57" alt="Path" src="/img/path-7.svg" />
                <img className="path-58" alt="Path" src="/img/path-8.svg" />
                <img className="path-59" alt="Path" src="/img/path-9.svg" />
                <img className="path-60" alt="Path" src="/img/path-10.svg" />
                <img className="shape-4" alt="Shape" src="/img/shape.svg" />
                <img className="path-61" alt="Path" src="/img/path-11.svg" />
                <img className="path-62" alt="Path" src="/img/path-12.svg" />
                <img className="path-63" alt="Path" src="/img/path-13.svg" />
                <img className="path-64" alt="Path" src="/img/path-14.svg" />
                <img className="path-65" alt="Path" src="/img/path-15.svg" />
                <img className="path-66" alt="Path" src="/img/path-16.svg" />
                <img className="path-67" alt="Path" src="/img/path-17.svg" />
                <img className="path-68" alt="Path" src="/img/path-18.svg" />
                <img className="path-69" alt="Path" src="/img/path-19.svg" />
                <img className="path-70" alt="Path" src="/img/path-20.svg" />
              </div>
            </div>
            <div className="group-20">
              <div className="overlap-31">
                <div className="overlap-32">
                  <div className="overlap-33">
                    <img className="path-71" alt="Path" src="/img/path-21.svg" />
                    <img className="path-72" alt="Path" src="/img/path-22.svg" />
                    <img className="path-73" alt="Path" src="/img/path-23.svg" />
                    <img className="path-74" alt="Path" src="/img/path-24.svg" />
                    <img className="path-75" alt="Path" src="/img/path-25.svg" />
                    <img className="path-76" alt="Path" src="/img/path-26.svg" />
                    <img className="path-77" alt="Path" src="/img/path-27.svg" />
                    <img className="path-78" alt="Path" src="/img/path-28.svg" />
                    <img className="path-79" alt="Path" src="/img/path-29.svg" />
                    <img className="path-80" alt="Path" src="/img/path-30.svg" />
                    <img className="path-81" alt="Path" src="/img/path-31.svg" />
                    <img className="path-82" alt="Path" src="/img/path-32.svg" />
                    <img className="path-83" alt="Path" src="/img/path-33.svg" />
                    <img className="path-84" alt="Path" src="/img/path-34.svg" />
                    <img className="path-85" alt="Path" src="/img/path-35.svg" />
                    <img className="path-86" alt="Path" src="/img/path-36.svg" />
                    <img className="path-87" alt="Path" src="/img/path-37.svg" />
                    <img className="path-88" alt="Path" src="/img/path-38.svg" />
                    <img className="shape-5" alt="Shape" src="/img/shape-1.svg" />
                    <img className="path-89" alt="Path" src="/img/path-39.svg" />
                    <img className="path-90" alt="Path" src="/img/path-40.svg" />
                    <img className="oval-6" alt="Oval" src="/img/oval-2.svg" />
                    <img className="path-91" alt="Path" src="/img/path-41.svg" />
                    <img className="path-92" alt="Path" src="/img/path-42.svg" />
                    <img className="shape-6" alt="Shape" src="/img/shape-2.svg" />
                    <img className="path-93" alt="Path" src="/img/path-43.svg" />
                    <img className="path-94" alt="Path" src="/img/path-45.svg" />
                    <img className="path-95" alt="Path" src="/img/path-46.svg" />
                  </div>
                  <img className="path-96" alt="Path" src="/img/path-44.svg" />
                  <img className="path-97" alt="Path" src="/img/path-47.svg" />
                  <img className="path-98" alt="Path" src="/img/path-50.svg" />
                </div>
                <img className="path-99" alt="Path" src="/img/path-48.svg" />
              </div>
              <img className="path-100" alt="Path" src="/img/path-49.svg" />
            </div>
          </div>
        </div>
        <div className="text-20">
          <div className="text-21">
            <div className="get-more-things-done-2">Painel de administrador</div>
            <p className="search-sourcing-the-2">Adicione, edite e elimine os episódios dos seu podcast.</p>
          </div>
        </div>
        <FormLogIn
          buttonGroupButtonLabel="Entrar"
          className="form-log-in-3"
          inputFieldHasDiv={false}
          inputFieldInputClassName="form-log-in-4"
          inputFieldInputClassNameOverride="form-log-in-4"
          inputFieldLabel="Senha"
          inputFieldVisible={false}
          textLinkText=""
        />
      </div>
    </div>
  );
};
