/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldEdit210 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-edit-2-10 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-101"
        d="M21 22H3C2.59 22 2.25 21.66 2.25 21.25C2.25 20.84 2.59 20.5 3 20.5H21C21.41 20.5 21.75 20.84 21.75 21.25C21.75 21.66 21.41 22 21 22Z"
        fill="#18A0FB"
      />
      <path
        className="path-101"
        d="M19.0201 3.48C17.0801 1.54 15.1801 1.49 13.1901 3.48L11.9801 4.69C11.8801 4.79 11.8401 4.95 11.8801 5.09C12.6401 7.74001 14.7601 9.86 17.4101 10.62C17.4501 10.63 17.4901 10.64 17.5301 10.64C17.6401 10.64 17.7401 10.6 17.8201 10.52L19.0201 9.31001C20.0101 8.33001 20.4901 7.38 20.4901 6.42C20.5001 5.43 20.0201 4.47 19.0201 3.48Z"
        fill="#18A0FB"
      />
      <path
        className="path-101"
        d="M15.6098 11.53C15.3198 11.39 15.0398 11.25 14.7698 11.09C14.5498 10.96 14.3398 10.82 14.1298 10.67C13.9598 10.56 13.7598 10.4 13.5698 10.24C13.5498 10.23 13.4798 10.17 13.3998 10.09C13.0698 9.80999 12.6998 9.44999 12.3698 9.04999C12.3398 9.02999 12.2898 8.95999 12.2198 8.86999C12.1198 8.74999 11.9498 8.54999 11.7998 8.31999C11.6798 8.16999 11.5398 7.94999 11.4098 7.72999C11.2498 7.45999 11.1098 7.18999 10.9698 6.90999C10.9486 6.86459 10.9281 6.81943 10.9083 6.77452C10.7607 6.44121 10.3261 6.34376 10.0683 6.60152L4.33983 12.33C4.20983 12.46 4.08983 12.71 4.05983 12.88L3.51983 16.71C3.41983 17.39 3.60983 18.03 4.02983 18.46C4.38983 18.81 4.88983 19 5.42983 19C5.54983 19 5.66983 18.99 5.78983 18.97L9.62983 18.43C9.80983 18.4 10.0598 18.28 10.1798 18.15L15.9011 12.4287C16.1607 12.1691 16.0628 11.7237 15.7252 11.5796C15.6872 11.5634 15.6488 11.5469 15.6098 11.53Z"
        fill="#18A0FB"
      />
    </svg>
  );
};
