/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldLogout2 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-logout-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-101"
        d="M7.2 22L9.8 22C13 22 15 20 15 16.8V12.75L8.75 12.75C8.34 12.75 8 12.41 8 12C8 11.59 8.34 11.25 8.75 11.25L15 11.25V7.2C15 4 13 2 9.8 2L7.21 2C4.01 2 2.01 4 2.01 7.2L2.01 16.8C2 20 4 22 7.2 22Z"
        fill="white"
      />
      <path
        className="path-101"
        d="M19.4396 12.75L17.3696 14.82C17.2196 14.97 17.1496 15.16 17.1496 15.35C17.1496 15.54 17.2196 15.74 17.3696 15.88C17.6596 16.17 18.1396 16.17 18.4296 15.88L21.7796 12.53C22.0696 12.24 22.0696 11.76 21.7796 11.47L18.4296 8.12C18.1396 7.83 17.6596 7.83 17.3696 8.12C17.0796 8.41 17.0796 8.89 17.3696 9.18L19.4396 11.25L14.9996 11.25V12.75L19.4396 12.75Z"
        fill="white"
      />
    </svg>
  );
};
