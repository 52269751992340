/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const InputField = ({
  description = "Description",
  error = "Error",
  hasLabel = true,
  hasError = false,
  value = "Value",
  hasDescription = false,
  label = "Label",
  state,
  valueType,
  className,
  inputClassName,
  hasDiv = true,
}) => {
  return (
    <div className={`input-field ${className}`}>
      {hasLabel && <div className={`label ${state}`}>{label}</div>}

      <div className={`input state-${state} ${inputClassName}`}>
        {hasDiv && <div className={`value state-0-${state} value-type-${valueType}`}>{value}</div>}
      </div>
    </div>
  );
};

InputField.propTypes = {
  description: PropTypes.string,
  error: PropTypes.string,
  hasLabel: PropTypes.bool,
  hasError: PropTypes.bool,
  value: PropTypes.string,
  hasDescription: PropTypes.bool,
  label: PropTypes.string,
  state: PropTypes.oneOf(["disabled", "error", "default"]),
  valueType: PropTypes.oneOf(["placeholder", "default"]),
  hasDiv: PropTypes.bool,
};
