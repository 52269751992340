import React from "react";
import { Link } from "react-router-dom";
import { VuesaxLinearMinus } from "../../components/VuesaxLinearMinus";
import { VuesaxBoldDiscover2 } from "../../icons/VuesaxBoldDiscover2";
import { VuesaxBoldEdit210 } from "../../icons/VuesaxBoldEdit210";
import { VuesaxBoldElement31 } from "../../icons/VuesaxBoldElement31";
import { VuesaxBoldGallery13 } from "../../icons/VuesaxBoldGallery13";
import { VuesaxBoldLinkSquare1 } from "../../icons/VuesaxBoldLinkSquare1";
import { VuesaxBoldLogout2 } from "../../icons/VuesaxBoldLogout2";
import { VuesaxBoldTickSquare1 } from "../../icons/VuesaxBoldTickSquare1";
import { VuesaxLinearSearchNormal1 } from "../../icons/VuesaxLinearSearchNormal1";

import "./style.css";

export const Podcast = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const abrirModal = () => {
    setIsModalOpen(true);
  };

  const fecharModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="podcast">
      <div className="div-6">
        <div className="overlap-18">
          <VuesaxBoldGallery13 className="icon-instance-node-2" />
        </div>
        <div className="group-13">
          <div className="group-14">
            <div className="text-wrapper-100">Bem vindo, Enrico</div>
            <p className="text-wrapper-101">Qua, 09 de Outubro de 2024</p>
          </div>
        </div>
        <div className="group-15">
          <div className="overlap-group-8">
            <VuesaxLinearSearchNormal1 className="vuesax-linear-search-normal-1" />
            <div className="text-wrapper-102">Buscar</div>
          </div>
        </div>
        <img className="vector-12" alt="Vector" src="/img/vector-11.svg" />
        <div className="group-16">
          <Link className="vuesax-bold-logout-2-wrapper" to="/index">
            <VuesaxBoldLogout2 className="icon-instance-node-2" />
          </Link>
          <div className="text-wrapper-103">Menu</div>
          <VuesaxBoldElement31 className="vuesax-bold-element-3-1" color="#18A0FB" />
          <VuesaxBoldDiscover2 className="vuesax-bold-discover-2" />
        </div>
        <Link className="group-17" to="/frame-29">
          <div className="overlap-19">
            <div className="rectangle-5" />
            <div className="text-wrapper-104">Upload novo episódio</div>
          </div>
        </Link>
        <div className="group-18">
          <div className="overlap-20">
            <div className="rectangle-6" />
            <div className="text-wrapper-105">Excluir selecionados</div>
          </div>
        </div>
        <div className="text-wrapper-106">Episódios</div>
        <div className="overlap-21">
          <VuesaxLinearMinus className="vuesax-linear-minus-5" />
          <VuesaxLinearMinus className="vuesax-linear-minus-6" />
          <VuesaxLinearMinus className="vuesax-linear-minus-7" />
          <VuesaxLinearMinus className="vuesax-linear-minus-8" />
          <VuesaxLinearMinus className="vuesax-linear-minus-9" />
          <VuesaxLinearMinus className="vuesax-linear-minus-10" />
          <div className="text-wrapper-107">Thumbnail</div>
          <div className="text-wrapper-108">Nome</div>
          <div className="text-wrapper-109">Nome episódio</div>
          <div className="text-wrapper-110">Nome episódio</div>
          <div className="text-wrapper-111">Nome episódio</div>
          <div className="text-wrapper-112">Nome episódio</div>
          <div className="text-wrapper-113">Nome episódio</div>
          <div className="text-wrapper-114">Nome episódio</div>
          <div className="text-wrapper-115">Nome episódio</div>
          <div className="text-wrapper-116">01:45:08</div>
          <div className="text-wrapper-117">01:45:08</div>
          <div className="text-wrapper-118">01:45:08</div>
          <div className="text-wrapper-119">01:45:08</div>
          <div className="text-wrapper-120">01:45:08</div>
          <div className="text-wrapper-121">01:45:08</div>
          <div className="text-wrapper-122">01:45:08</div>
          <div className="text-wrapper-123">10/08/2022</div>
          <div className="text-wrapper-124">10/08/2022</div>
          <div className="text-wrapper-125">10/08/2022</div>
          <div className="text-wrapper-126">10/08/2022</div>
          <div className="text-wrapper-127">10/08/2022</div>
          <div className="text-wrapper-128">10/08/2022</div>
          <div className="text-wrapper-129">10/08/2022</div>
          <div className="text-wrapper-130">Publicado</div>
          <div className="text-wrapper-131">Publicado</div>
          <div className="text-wrapper-132">Publicado</div>
          <div className="text-wrapper-133">Publicado</div>
          <div className="text-wrapper-134">Publicado</div>
          <div className="text-wrapper-135">Publicado</div>
          <div className="text-wrapper-136">Publicado</div>
          <div className="text-wrapper-137">Duração</div>
          <div className="text-wrapper-138">Data publicado</div>
          <div className="text-wrapper-139">Status</div>
          <img className="vector-13" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-14" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-15" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-16" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-17" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-18" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-19" alt="Vector" src="/img/vector-436.svg" />
          <div className="overlap-22">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery-12" />
          </div>
          <div className="overlap-23">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery-12" />
          </div>
          <div className="overlap-24">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery-12" />
          </div>
          <div className="overlap-25">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery-12" />
          </div>
          <div className="overlap-26">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery-12" />
          </div>
          <div className="overlap-27">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery-12" />
          </div>
          <div className="overlap-28">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery-12" />
          </div>
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-6" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-7" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-8" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-9" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-10" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-11" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-12" />
     
 
          <VuesaxBoldTickSquare1 className="vuesax-bold-tick-square-1-instance" />
          <VuesaxBoldTickSquare1 className="vuesax-bold-tick-2" />
        </div>
        <div className="overlap-29">
          <VuesaxBoldGallery13 className="vuesax-bold-gallery-13-instance" />
        </div>
      </div>
    </div>
  );
};
