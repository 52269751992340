/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldEdit28 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-edit-2-8 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-101"
        d="M21 22H3C2.59 22 2.25 21.66 2.25 21.25C2.25 20.84 2.59 20.5 3 20.5H21C21.41 20.5 21.75 20.84 21.75 21.25C21.75 21.66 21.41 22 21 22Z"
        fill="#18A0FB"
      />
      <path
        className="path-101"
        d="M19.0196 3.48C17.0796 1.54 15.1796 1.49 13.1896 3.48L11.9796 4.69C11.8796 4.79 11.8396 4.95 11.8796 5.09C12.6396 7.74001 14.7596 9.86 17.4096 10.62C17.4496 10.63 17.4896 10.64 17.5296 10.64C17.6396 10.64 17.7396 10.6 17.8196 10.52L19.0196 9.31001C20.0096 8.33001 20.4896 7.38 20.4896 6.42C20.4996 5.43 20.0196 4.47 19.0196 3.48Z"
        fill="#18A0FB"
      />
      <path
        className="path-101"
        d="M15.6103 11.53C15.3203 11.39 15.0403 11.25 14.7703 11.09C14.5503 10.96 14.3403 10.82 14.1303 10.67C13.9603 10.56 13.7603 10.4 13.5703 10.24C13.5503 10.23 13.4803 10.17 13.4003 10.09C13.0703 9.80999 12.7003 9.44999 12.3703 9.04999C12.3403 9.02999 12.2903 8.95999 12.2203 8.86999C12.1203 8.74999 11.9503 8.54999 11.8003 8.31999C11.6803 8.16999 11.5403 7.94999 11.4103 7.72999C11.2503 7.45999 11.1103 7.18999 10.9703 6.90999C10.9491 6.86459 10.9286 6.81943 10.9088 6.77452C10.7612 6.44121 10.3265 6.34376 10.0688 6.60152L4.34032 12.33C4.21032 12.46 4.09032 12.71 4.06032 12.88L3.52032 16.71C3.42032 17.39 3.61032 18.03 4.03032 18.46C4.39032 18.81 4.89032 19 5.43032 19C5.55032 19 5.67032 18.99 5.79032 18.97L9.63032 18.43C9.81032 18.4 10.0603 18.28 10.1803 18.15L15.9016 12.4287C16.1612 12.1691 16.0633 11.7237 15.7257 11.5796C15.6877 11.5634 15.6492 11.5469 15.6103 11.53Z"
        fill="#18A0FB"
      />
    </svg>
  );
};
