/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldDiscover2 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-discover-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" opacity="0.3">
        <path
          className="path-101"
          d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM10.5 16.13C9.05 16.13 7.88 14.95 7.88 13.51C7.88 10.41 10.4 7.89 13.5 7.89C14.95 7.89 16.12 9.07 16.12 10.51C16.12 13.6 13.6 16.13 10.5 16.13Z"
          fill="#292D32"
        />
      </g>
    </svg>
  );
};
