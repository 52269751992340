import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Size48 } from "../../icons/Size48";
import "./style.css";
import ReactAudioPlayer from 'react-audio-player';
import { fetchEpisodes } from './index.js';
import { generateAudioUrl } from './index.js';


export const Index = () => {

  // Busca os episódios do podcast
  const [playlists, setPlaylists] = useState([]);
  useEffect(() => {
    const getEpisodes = async () => {
      try {
        const data = await fetchEpisodes();
        setPlaylists(data);
      } catch (error) {
        console.error('Erro ao buscar episódios:', error);
      }
    };

    getEpisodes();
  }, []);

  // Último episódio
  let ultimoEpisodio = "Clique para ir para os episódios";
  if (playlists) {
    let ultimoEpisodio = playlists[playlists.length - 1];
  }

  // Controla a visibilidade do player de áudio e o áudio que está tocando
  const [isPlayerVisible, setPlayerVisible] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');

  const CustomAudioPlayer = (urlAudio) => {
    const url = generateAudioUrl(urlAudio, setPlayerVisible);
    setAudioUrl(url);
  };

  // Scrolla para o player de áudio
  const scrollToElement = (e) => {
    e.preventDefault();
    const element = document.getElementById('text-wrapper-20');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToElement2 = (e) => {
    e.preventDefault();
    const element = document.getElementById('text-wrapper-11');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Controla o player de áudio
  const [activeAudioUrl, setActiveAudioUrl] = useState(null);
  const [audioKey, setAudioKey] = useState(0);

  const handlePlayClick = (audioUrl) => {
    setActiveAudioUrl(audioUrl);
    setAudioKey(prevKey => prevKey + 1); // Incrementa a chave para forçar a recriação do player
  };

  return (
    
    <div className="index">
      <div>
 
    </div>
      <div className="div-2">
        <footer className="footer">
          <div className="frame-2">
            <div className="logo">
              <img className="adaptive-audio-mic" alt="Adaptive audio mic" src="/img/adaptive-audio-mic.png" />
              <div className="text-wrapper-8">Saúde Mental em Campo</div>
            </div>
            <p className="element-linearity-gmb">Copyright © 2024 Sullyvan Marks</p>
          </div>
        </footer>
        <div className="all-inclusive">
          <Size48 className="arrow-up" />
        </div>
        <div className="connection">
          <div className="text-4">
            <div className="text-wrapper-9">Nossas redes sociais</div>
          </div>
          <div className="group">
            <div className="logo-2">
              <img className="img" alt="Adaptive audio mic" src="/img/adaptive-audio-mic-1.png" />
              <div className="text-wrapper-10">Saúde Mental em Campo</div>
            </div>
            <img className="all-inclusive-2" alt="All inclusive" src="/img/all-inclusive.svg" />
            <div className="circles-wrapper">
              <img className="img-2" alt="Circles" src="/img/circles.png" />
            </div>
            <div className="contactless-wrapper">
              <img className="img-2" alt="Contactless" src="/img/contactless.png" />
            </div>
            <div className="img-wrapper">
              <img className="img-3" alt="Circles" src="/img/circles-1.png" />
            </div>
            <img className="all-inclusive-3" alt="All inclusive" src="/img/all-inclusive-1.svg" />
            <img className="all-inclusive-4" alt="All inclusive" src="/img/all-inclusive-2.svg" />
            <div className="editor-choice-wrapper">
              <img className="img-2" alt="Editor choice" src="/img/editor-choice.png" />
            </div>
            <div className="all-inclusive-5">
              <img className="img-2" alt="Editor choice" src="/img/editor-choice.png" />
            </div>
            <div className="all-inclusive-6">
              <img className="editor-choice" alt="Editor choice" src="/img/editor-choice-2.png" />
            </div>
          </div>
        </div>
        <div className="host">
          <div className="text-5">
            <div className="frame-3">
              <div id="text-wrapper-11" className="text-wrapper-11" >Nosso host</div>
            </div>
            <p className="text-wrapper-99">A voz por trás do nosso podcast</p>
          </div>
          <div className="card">
          <img className="image" alt="Image" src="/img/image-2.png" />
          <a className="frame-5" href="https://instagram.com" rel="noopener noreferrer" target="_blank">
              <div className="text-wrapper-14">Instagram</div>
            </a>
            
            <div className="frame-4">
              <div className="text-wrapper-12">Enrico Sommerfeld</div>
              <p className="text-wrapper-13">
              Meu nome é Enrico Sommerfeld Claramunt estudo jornalismo na Universidade Presbiteriana Mackenzie desde 2021 e, ao longo da minha trajetória, tive a oportunidade de trabalhar na Secretaria Municipal de Inovação e Tecnologia, onde desenvolvi habilidades em planejamento e comunicação.

Atualmente, faço parte da Press FC, uma assessoria esportiva, onde aprofundo minha experiência no jornalismo esportivo, conectando histórias do esporte ao público. Sou movido pela paixão em informar e transformar através da comunicação
              </p>
            </div>
            
          </div>
        </div>
        <div className="lates-episodes">
          <div className="frame-6">
            <div className="scrollable-container" >
            {playlists.map((section, index) => (
              <div className="frame-7" key={index}>
                <div className="frame-8">
                  <div className="card-2">

                    <img className="img-4" alt="Headphones" src={section.thumb_url || "/img/headphones.svg"} />
                    <div className="text-3">
                      <div className="text-wrapper-8">{section.title}</div>
                      <div className="card-body">
                        <div className="frame-9">
                          <div className="frame-10" />
                          <div className="frame-11">
                            <div className="text-wrapper-9" >{section.description} 
                            </div>
                          </div>
                        </div>
                        
                        {activeAudioUrl === section.audio_url && (
                          <audio controls className="audio-player-overlay" autoPlay key={audioKey}>
                            <source src={section.audio_url} type="audio/mpeg" />
                          </audio>
                        )}
                        <a onClick={() => handlePlayClick(section.audio_url)} rel="noopener noreferrer" target="_blank" key={section.audio_url}>
                          <img className="play-arrow" alt="Play arrow" src="/img/play-arrow.svg" />
                        </a>
                        
                        
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            ))}
            </div>
        
          
            <div className="frame-11" >
             {/* <div className="text-wrapper-15">Playlists do Podcast</div> */}
 
             {/* <div className="frame-12">
                <a
                  className="frame-13"
                  href="https://open.spotify.com/browse/featured"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="images" />
                  <div className="group-2">
                    <div className="text-wrapper-17">1 Episódio</div>
                    <div className="text-wrapper-18">Treinos</div>
                  </div>
                </a>
                <a
                  className="frame-13"
                  href="https://open.spotify.com/browse/featured"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="images" />
                  <div className="group-2">
                    <div className="text-wrapper-17">1 Episódio</div>
                    <div className="text-wrapper-18">Ansiedade</div>
                  </div>
                </a>
                <a
                  className="frame-13"
                  href="https://open.spotify.com/browse/featured"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="images" />
                  <div className="group-2">
                    <div className="text-wrapper-17">1 Episódio</div>
                    <div className="text-wrapper-18">Pressão</div>
                  </div>
                </a>
              </div>  */}
            {/*  <a
                className="frame-14"
                href="https://open.spotify.com/browse/featured"
                rel="noopener noreferrer"
                target="_blank"
              >
                <p className="text-wrapper-19">Ver todas as playlists no spotify</p>
              </a> */}
            </div>
          </div>
          <div className="frame-15">
 
            <div className="frame-16">
              <div className="text-wrapper-11">Episódios</div>
            </div>
       
            <div id="text-wrapper-20" className="text-wrapper-20">Nossas conversas</div>
          </div>

        </div>
        <div className="about-us">
      
          <div className="images-2">
            <div className="overlap-group-2">
              <div className="images-3" />
              <div className="images-4" />
              <div className="frame-17">
                <img className="img-4" alt="Speech to text" src="/img/speech-to-text.svg" />
                <div className="frame-18">
                  <div className="text-wrapper-21" onClick={scrollToElement}>Último episódio</div>
                  <div className="text-wrapper-22">{ultimoEpisodio}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-7">
            <p className="text-wrapper-23">Análises sobre saúde mental em campo</p>
            <div className="frame-19">
              <div className="text-wrapper-24">Sobre nosso podcast</div>
            </div>
            <p className="text-wrapper-25">
              Mergulhamos nas questões emocionais e psicológicas que envolvem o mundo do esporte. Em cada episódio,
              trazemos conversas com atletas, psicólogos, treinadores e especialistas, discutindo temas como pressão por
              resultados, superação de desafios, ansiedade, burnout e o impacto da aposentadoria na vida dos
              esportistas. Vamos além das vitórias e derrotas, explorando como a mente dos atletas é uma parte
              fundamental do seu desempenho e bem-estar. Acompanhe-nos nessa jornada que revela o lado humano do
              esporte, onde cuidar da mente é tão importante quanto treinar o corpo.
            </p>
          </div>
        </div>
        <div className="overlap">
          <header className="header">
            <div className="overlap-2">
              <div className="overlap-2">
                <div className="ellipse" />
                <div className="images-5" />
                <div className="text-8">
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <div className="rectangle" />
                      <p className="text-wrapper-26">Porque o jogo mais importante acontece na mente</p>
                    </div>
                  </div>
                  <p className="text-wrapper-27">
                    Focado em mostrar o lado humano dos atletas, destacamos a importância de cuidar da mente para um bom
                    desempenho e bem-estar
                  </p>
                </div>
                <img className="vector-2" alt="Vector" src="/img/vector-1.svg"  />
                <img className="footballer" alt="Footballer" src="/img/footballer-1.png"  />
                <a
                  className="frame-20"
                  
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img className="img-4" alt="Speech to text" src="/img/speech-to-text.svg"  />
                  <div className="frame-18">
                    <div className="text-wrapper-21" onClick={scrollToElement}>Último episódio</div>
                    <p className="text-wrapper-22" >{ultimoEpisodio}</p>
                  </div>
                </a>
              </div>
              <a onClick={scrollToElement} rel="noopener noreferrer" target="_blank">
                <img className="frame-21" alt="Frame" src="/img/frame-1.png"  />
              </a>
              <div className="text-wrapper-28">Início</div>
            </div>
            <div className="text-9">
              <p className="text-wrapper-29">Toda terça e sábado às 20h um novo episódio</p>
              <div className="text-wrapper-18">Episódios semanais</div>
            </div>
            <div className="text-10">
              <div className="text-wrapper-30">Disponível</div>
              <div className="text-wrapper-31">em todas as plataformas</div>
            </div>
            <div className="theme">
              <div className="text-wrapper-18">Ouça em outras plataformas</div>
              <div className="frame-22">
                <a
                  className="frame-23"
                  href="https://open.spotify.com/show/4Ts9DVvzoXCDT7C6yb6xOX"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img className="img-3" alt="Target" src="/img/target.png" />
                  <div className="text-wrapper-16">Spotify</div>
                </a>
                <a className="frame-23"  rel="noopener noreferrer" target="_blank">
                  <img className="img-3" alt="Target" src="/img/target.png" />
                  <div className="text-wrapper-16">YouTube</div>
                </a>
                <div className="frame-23">
                  <img className="img-3" alt="Target" src="/img/target.png" />
                  <div className="text-wrapper-16">Apple Podcast</div>
                </div>
                <div className="frame-23">
                  <img className="img-3" alt="Target" src="/img/target.png" />
                  <div className="text-wrapper-16">Google Podcast</div>
                </div>
                <div className="frame-23">
                  <img className="img-3" alt="Target" src="/img/target.png" />
                  <div className="text-wrapper-16">Soundcloud</div>
                </div>
              </div>
            </div>
          </header>
          <div className="menu">
            <div className="logo">
              <img className="adaptive-audio-mic" alt="Adaptive audio mic" src="/img/adaptive-audio-mic-2.png" />
              <div className="text-wrapper-8">
                Saúde Mental
                <br />
                em Campo
              </div>
            </div>
            <div className="navbar">
              <div className="text-wrapper-32">Início </div>
              <div className="text-wrapper-32">Episódios</div>
              <div id="text-wrapper-32" className="text-wrapper-32" onClick={scrollToElement2}>Sobre mim</div>
              <Link className="text-wrapper-33" to="/login">
                Administrador
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
