/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldEdit2 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-edit-2 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-101"
        d="M21 22H3C2.59 22 2.25 21.66 2.25 21.25C2.25 20.84 2.59 20.5 3 20.5H21C21.41 20.5 21.75 20.84 21.75 21.25C21.75 21.66 21.41 22 21 22Z"
        fill="#18A0FB"
      />
      <path
        className="path-101"
        d="M19.02 3.48C17.08 1.54 15.18 1.49 13.19 3.48L11.98 4.69C11.88 4.79 11.84 4.95 11.88 5.09C12.64 7.74 14.76 9.86 17.41 10.62C17.45 10.63 17.49 10.64 17.53 10.64C17.64 10.64 17.74 10.6 17.82 10.52L19.02 9.31C20.01 8.33 20.49 7.38 20.49 6.42C20.5 5.43 20.02 4.47 19.02 3.48Z"
        fill="#18A0FB"
      />
      <path
        className="path-101"
        d="M15.61 11.53C15.32 11.39 15.04 11.25 14.77 11.09C14.55 10.96 14.34 10.82 14.13 10.67C13.96 10.56 13.76 10.4 13.57 10.24C13.55 10.23 13.48 10.17 13.4 10.09C13.07 9.81 12.7 9.45 12.37 9.05C12.34 9.03 12.29 8.96 12.22 8.87C12.12 8.75 11.95 8.55 11.8 8.32C11.68 8.17 11.54 7.95 11.41 7.73C11.25 7.46 11.11 7.19 10.97 6.91C10.9488 6.8646 10.9283 6.81944 10.9084 6.77454C10.7609 6.44122 10.3262 6.34377 10.0685 6.60153L4.34 12.33C4.21 12.46 4.09 12.71 4.06 12.88L3.52 16.71C3.42 17.39 3.61 18.03 4.03 18.46C4.39 18.81 4.89 19 5.43 19C5.55 19 5.67 18.99 5.79 18.97L9.63 18.43C9.81 18.4 10.06 18.28 10.18 18.15L15.9013 12.4287C16.1609 12.1691 16.0629 11.7237 15.7253 11.5796C15.6873 11.5634 15.6489 11.5469 15.61 11.53Z"
        fill="#18A0FB"
      />
      <g className="g" opacity="0" />
    </svg>
  );
};
