import React, { useState, useEffect } from 'react';


export const generateAudioUrl = (url, setPlayerVisible) => {
    let partURL = url.split("=")[1];
    partURL = partURL.replace(/ó/g, '%C3%83%C2%B3');
    const audioUrl = `https://ftp.saudementalemcampo.ngrok.app/audio?filename=${encodeURIComponent(partURL)}`;
    console.log(audioUrl);
    setPlayerVisible(true);
    return audioUrl;
  };


  export const fetchEpisodes = async () => {
    try {
      const response = await fetch('https://saudementalemcampo.ngrok.pro/api/episodes');
      if (!response.ok) {
        throw new Error('Erro ao buscar episódios');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao buscar episódios:', error);
      throw error;
    }
  };
  

  export const fetchEpisodes2 = async () => {
    try {
      const token = getAuthToken(); // Obtendo o token de autenticação
      const response = await fetch('https://saudementalemcampo.ngrok.pro/api/episodes', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Cabeçalho de autenticação
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Erro ao buscar episódios');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao buscar episódios:', error);
      throw error;
    }
  };
  
  // Exemplo de uso
  const MyComponent = () => {
    const [episodes, setEpisodes] = useState([]);
  
    useEffect(() => {
      fetchEpisodes()
        .then(setEpisodes)
        .catch(console.error);
    }, []);
  };