import React from "react";
import { NameCloseTypeFill } from "../../icons/NameCloseTypeFill";
import { Photo } from "../../icons/Photo";
import "./style.css";

export const Frame = () => {
  return (
    <div className="frame">
      <div className="frame-wrapper">
        <div className="overlap-group-wrapper">
          <div className="overlap-group">
            <div className="first-impression-add">
              <div className="title-icon">
                <div className="text-wrapper-2">Adicionar novo episódio</div>
                <NameCloseTypeFill className="icon" color="#ECEDF2" stroke="#282A37" />
              </div>
              <div className="upload">
                <div className="title-icon-2">
                  <img className="paper-upload" alt="Paper upload" src="/img/paper-upload.svg" />
                  <div className="title-subtitle">
                    <p className="p">Escolha o áudio para upload</p>
                    <div className="text-wrapper-3">Selecione arquivos .mp3</div>
                  </div>
                </div>
              </div>
              <div className="line">
                <img className="vector" alt="Vector" src="/img/vector-24238.svg" />
                <div className="text-wrapper-4">OR</div>
                <img className="vector" alt="Vector" src="/img/vector-24238.svg" />
              </div>
              <div className="title">
                <div className="import">
                  <div className="text-wrapper-5">Nome do episódio</div>
                  <div className="text" />
                </div>
              </div>
              <div className="title">
                <div className="import">
                  <div className="text-wrapper-5">Descrição</div>
                  <div className="text-2" />
                </div>
              </div>
              <div className="title">
                <div className="import">
                  <div className="text-wrapper-5">Playlist</div>
                  <div className="text-3" />
                </div>
              </div>
              <div className="title">
                <div className="import">
                  <div className="text-wrapper-5">Miniatura</div>
                  <div className="photo-wrapper">
                    <Photo className="photo-instance" />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn">
              <button className="div-wrapper">
                <div className="text-wrapper-6">Cancelar</div>
              </button>
              <button className="btn-2">
                <div className="text-wrapper-7">Upload</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
