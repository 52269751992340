import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Index } from "./screens/Index";
import { Frame } from "./screens/Frame";
import { Playlists } from "./screens/Playlists";
import { Login } from "./screens/Login";


import { Podcast } from "./screens/Podcast";
import { Website } from "./screens/Website";
import { Episodios } from "./screens/Episodios";
import { element } from "prop-types";


const router = createBrowserRouter([
  {
    path: "/*",
    element: <Index />,
  },
  {
    path: "/index",
    element: <Index />,
  },
  {
    path: "/frame-29",
    element: <Frame />,
  },
  {
    path: "/playlists",
    element: <Playlists />,
  },
  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "/podcast-2",
    element: <Podcast />,
  },
  {
    path: "/website",
    element: <Website />,
  },
  {
    path: "/episodios",
    element: <Episodios />,
  },
//  {
 //   path: "/upload",
 //   element: <UploadForm />,
 // },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
