/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { VuesaxBoldLogout } from "../../icons/VuesaxBoldLogout";
import "./style.css";

export const VuesaxBoldLogoutWrapper = () => {
  return <VuesaxBoldLogout className="vuesax-bold-logout" />;
};
