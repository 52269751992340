import React, { useState } from 'react';
import { FormLogIn } from "../../components/FormLogIn";
import "./style.css";
import { Link, useNavigate  } from 'react-router-dom';

export const Login = () => {
  const [text, setText] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setText(event.target.value); // Atualiza o estado com o valor do textarea
  };

  
  const handleClick = () => {
    if (text === '3?QdzJBRb') {
      navigate('/episodios');
    } else {
      alert('Código de acesso inválido');
    }
  };

  return (
    <div className="login">
      <div className="div-4">
        <div className="illustration">
          <div className="overlap-12">
            <img className="path" alt="Path" src="/img/path-1.svg" />
            <img className="path-2" alt="Path" src="/img/path-2.svg" />
            <img className="path-3" alt="Path" src="/img/path-3.svg" />
            <img className="oval" alt="Oval" src="/img/oval.svg" />
            <img className="oval-2" alt="Oval" src="/img/oval-1.svg" />
            <div className="group-8">
              <div className="overlap-group-5">
                <img className="path-4" alt="Path" src="/img/path-4.svg" />
                <img className="path-5" alt="Path" src="/img/path-5.svg" />
                <img className="path-6" alt="Path" src="/img/path-6.svg" />
                <img className="path-7" alt="Path" src="/img/path-7.svg" />
                <img className="path-8" alt="Path" src="/img/path-8.svg" />
                <img className="path-9" alt="Path" src="/img/path-9.svg" />
                <img className="path-10" alt="Path" src="/img/path-10.svg" />
                <img className="shape" alt="Shape" src="/img/shape.svg" />
                <img className="path-11" alt="Path" src="/img/path-11.svg" />
                <img className="path-12" alt="Path" src="/img/path-12.svg" />
                <img className="path-13" alt="Path" src="/img/path-13.svg" />
                <img className="path-14" alt="Path" src="/img/path-14.svg" />
                <img className="path-15" alt="Path" src="/img/path-15.svg" />
                <img className="path-16" alt="Path" src="/img/path-16.svg" />
                <img className="path-17" alt="Path" src="/img/path-17.svg" />
                <img className="path-18" alt="Path" src="/img/path-18.svg" />
                <img className="path-19" alt="Path" src="/img/path-19.svg" />
                <img className="path-20" alt="Path" src="/img/path-20.svg" />
              </div>
            </div>
            <div className="group-9">
              <div className="overlap-13">
                <div className="overlap-14">
                  <div className="overlap-15">
                    <img className="path-21" alt="Path" src="/img/path-21.svg" />
                    <img className="path-22" alt="Path" src="/img/path-22.svg" />
                    <img className="path-23" alt="Path" src="/img/path-23.svg" />
                    <img className="path-24" alt="Path" src="/img/path-24.svg" />
                    <img className="path-25" alt="Path" src="/img/path-25.svg" />
                    <img className="path-26" alt="Path" src="/img/path-26.svg" />
                    <img className="path-27" alt="Path" src="/img/path-27.svg" />
                    <img className="path-28" alt="Path" src="/img/path-28.svg" />
                    <img className="path-29" alt="Path" src="/img/path-29.svg" />
                    <img className="path-30" alt="Path" src="/img/path-30.svg" />
                    <img className="path-31" alt="Path" src="/img/path-31.svg" />
                    <img className="path-32" alt="Path" src="/img/path-32.svg" />
                    <img className="path-33" alt="Path" src="/img/path-33.svg" />
                    <img className="path-34" alt="Path" src="/img/path-34.svg" />
                    <img className="path-35" alt="Path" src="/img/path-35.svg" />
                    <img className="path-36" alt="Path" src="/img/path-36.svg" />
                    <img className="path-37" alt="Path" src="/img/path-37.svg" />
                    <img className="path-38" alt="Path" src="/img/path-38.svg" />
                    <img className="shape-2" alt="Shape" src="/img/shape-1.svg" />
                    <img className="path-39" alt="Path" src="/img/path-39.svg" />
                    <img className="path-40" alt="Path" src="/img/path-40.svg" />
                    <img className="oval-3" alt="Oval" src="/img/oval-2.svg" />
                    <img className="path-41" alt="Path" src="/img/path-41.svg" />
                    <img className="path-42" alt="Path" src="/img/path-42.svg" />
                    <img className="shape-3" alt="Shape" src="/img/shape-2.svg" />
                    <img className="path-43" alt="Path" src="/img/path-43.svg" />
                    <img className="path-44" alt="Path" src="/img/path-45.svg" />
                    <img className="path-45" alt="Path" src="/img/path-46.svg" />
                  </div>
                  <img className="path-46" alt="Path" src="/img/path-44.svg" />
                  <img className="path-47" alt="Path" src="/img/path-47.svg" />
                  <img className="path-48" alt="Path" src="/img/path-50.svg" />
                </div>
                <img className="path-49" alt="Path" src="/img/path-48.svg" />
              </div>
              <img className="path-50" alt="Path" src="/img/path-49.svg" />
            </div>
          </div>
        </div>
        <div className="text-11">
          <div className="text-12">
            <div className="get-more-things-done">Painel de administrador</div>
            <p className="search-sourcing-the">Adicione, edite e elimine os episódios dos seu podcast.</p>
          </div>
        </div>
        <div className='box'>
          <div className='input'>
          <textarea value={text} onChange={handleChange} placeholder='Código de acesso' className='upload-text-6 upload-no-resize'  />
            <span className='value' />
          </div>
        </div>
        <button className='button button-enter'  onClick={handleClick} >
          Entrar
        </button>
      </div>
    </div>
  );
};
