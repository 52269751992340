/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Button = ({
  hasIconStart = false,
  hasIconEnd = false,
  label = "Button",
  variant,
  stateProp,
  size,
  className,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    variant: variant || "primary",
    state: stateProp || "default",
    size: size || "medium",
  });

  return (
    <button
      className={`button state-1-${state.state} ${state.size} ${state.variant} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className="text-wrapper">{label}</div>
    </button>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}

Button.propTypes = {
  hasIconStart: PropTypes.bool,
  hasIconEnd: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "neutral", "subtle"]),
  stateProp: PropTypes.oneOf(["disabled", "hover", "default"]),
  size: PropTypes.oneOf(["medium", "small"]),
};
