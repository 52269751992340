/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ButtonGroup } from "../ButtonGroup";
import { InputField } from "../InputField";
import { TextLink } from "../TextLink";
import "./style.css";

export const FormLogIn = ({
  className,
  inputFieldInputClassName,
  inputFieldHasDiv,
  inputFieldInputClassNameOverride,
  inputFieldVisible,
  inputFieldLabel = "Password",
  buttonGroupButtonLabel = "Sign In",
  textLinkText = "Forgot password?",
  to,
}) => {
  return (
    <div className={`form-log-in ${className}`}>
      <InputField
        className="design-component-instance-node"
        hasDiv={inputFieldHasDiv}
        inputClassName={inputFieldInputClassName}
        label="Email"
        state="default"
        value="Value"
        valueType="placeholder"
      />
      <InputField
        className="design-component-instance-node"
        hasDiv={inputFieldVisible}
        inputClassName={inputFieldInputClassNameOverride}
        label={inputFieldLabel}
        state="default"
        value="Value"
        valueType="placeholder"
      />
      <ButtonGroup
        align="justify"
        buttonLabel={buttonGroupButtonLabel}
        buttonStart={false}
        className="button-group-instance"
        to={to}
      />
      <TextLink className="design-component-instance-node" text={textLinkText} />
    </div>
  );
};

FormLogIn.propTypes = {
  inputFieldHasDiv: PropTypes.bool,
  inputFieldVisible: PropTypes.bool,
  inputFieldLabel: PropTypes.string,
  buttonGroupButtonLabel: PropTypes.string,
  textLinkText: PropTypes.string,
  to: PropTypes.string,
};
