import React from "react";
import { Link } from "react-router-dom";
import { VuesaxLinearMinus } from "../../components/VuesaxLinearMinus";
import { VuesaxBoldDiscover1 } from "../../icons/VuesaxBoldDiscover1";
import { VuesaxBoldEdit28 } from "../../icons/VuesaxBoldEdit28";
import { VuesaxBoldElement31 } from "../../icons/VuesaxBoldElement31";
import { VuesaxBoldGallery13 } from "../../icons/VuesaxBoldGallery13";
import { VuesaxBoldLinkSquare1 } from "../../icons/VuesaxBoldLinkSquare1";
import { VuesaxBoldLogout2 } from "../../icons/VuesaxBoldLogout2";
import { VuesaxBoldTickSquare1 } from "../../icons/VuesaxBoldTickSquare1";
import { VuesaxLinearSearchNormal1 } from "../../icons/VuesaxLinearSearchNormal1";
import "./style.css";

export const Playlists = () => {
  return (
    <div className="playlists">
      <div className="div-3">
        <div className="vuesax-bold-gallery-wrapper">
          <VuesaxBoldGallery13 className="icon-instance-node" />
        </div>
        <div className="group-wrapper">
          <div className="group-4">
            <div className="text-wrapper-34">Bem vindo, Enrico</div>
            <p className="text-wrapper-35">Qua, 09 de Outubro de 2024</p>
          </div>
        </div>
        <div className="group-5">
          <div className="overlap-group-4">
            <VuesaxLinearSearchNormal1 className="vuesax-linear-search" />
            <div className="text-wrapper-36">Buscar</div>
          </div>
        </div>
        <img className="vector-3" alt="Vector" src="/img/vector-11.svg" />
        <div className="group-6">
          <Link className="vuesax-bold-logout-wrapper" to="/index">
            <VuesaxBoldLogout2 className="icon-instance-node" />
          </Link>
          <div className="text-wrapper-37">Menu</div>
          <VuesaxBoldElement31 className="vuesax-bold-element" color="#BFC0C2" />
          <VuesaxBoldDiscover1 className="vuesax-bold-discover" />
        </div>
        <Link className="overlap-wrapper" to="/frame-29">
          <div className="overlap-3">
            <div className="rectangle-2" />
            <div className="text-wrapper-38">Nova playlist</div>
          </div>
        </Link>
        <div className="group-7">
          <div className="overlap-4">
            <div className="rectangle-3" />
            <div className="text-wrapper-39">Excluir selecionados</div>
          </div>
        </div>
        <div className="text-wrapper-40">Playlists</div>
        <div className="overlap-5">
          <VuesaxLinearMinus className="vuesax-linear-minus-square" />
          <VuesaxLinearMinus className="vuesax-linear-minus-instance" />
          <VuesaxLinearMinus className="vuesax-linear-minus-square-instance" />
          <VuesaxLinearMinus className="vuesax-linear-minus-2" />
          <VuesaxLinearMinus className="vuesax-linear-minus-3" />
          <VuesaxLinearMinus className="vuesax-linear-minus-4" />
          <div className="text-wrapper-41">Thumbnail</div>
          <div className="text-wrapper-42">Nome</div>
          <div className="text-wrapper-43">Playlist</div>
          <div className="text-wrapper-44">Playlist</div>
          <div className="text-wrapper-45">Playlist</div>
          <div className="text-wrapper-46">Playlist</div>
          <div className="text-wrapper-47">Playlist</div>
          <div className="text-wrapper-48">Playlist</div>
          <div className="text-wrapper-49">Playlist</div>
          <div className="text-wrapper-50">12</div>
          <div className="text-wrapper-51">12</div>
          <div className="text-wrapper-52">12</div>
          <div className="text-wrapper-53">12</div>
          <div className="text-wrapper-54">12</div>
          <div className="text-wrapper-55">12</div>
          <div className="text-wrapper-56">12</div>
          <div className="text-wrapper-57">10/08/2022</div>
          <div className="text-wrapper-58">10/08/2022</div>
          <div className="text-wrapper-59">10/08/2022</div>
          <div className="text-wrapper-60">10/08/2022</div>
          <div className="text-wrapper-61">10/08/2022</div>
          <div className="text-wrapper-62">10/08/2022</div>
          <div className="text-wrapper-63">10/08/2022</div>
          <div className="text-wrapper-64">Publicado</div>
          <div className="text-wrapper-65">Publicado</div>
          <div className="text-wrapper-66">Publicado</div>
          <div className="text-wrapper-67">Publicado</div>
          <div className="text-wrapper-68">Publicado</div>
          <div className="text-wrapper-69">Publicado</div>
          <div className="text-wrapper-70">Publicado</div>
          <div className="text-wrapper-71">Episódios</div>
          <div className="text-wrapper-72">Data atualização</div>
          <div className="text-wrapper-73">Status</div>
          <img className="vector-4" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-5" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-6" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-7" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-8" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-9" alt="Vector" src="/img/vector-436.svg" />
          <img className="vector-10" alt="Vector" src="/img/vector-436.svg" />
          <div className="vuesax-bold-gallery-13-wrapper">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery" />
          </div>
          <div className="vuesax-bold-gallery-12-wrapper">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery" />
          </div>
          <div className="overlap-6">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery" />
          </div>
          <div className="overlap-7">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery" />
          </div>
          <div className="overlap-8">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery" />
          </div>
          <div className="overlap-9">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery" />
          </div>
          <div className="overlap-10">
            <VuesaxBoldGallery13 className="vuesax-bold-gallery" />
          </div>
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-square-1" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-square-1-instance" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-2" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-3" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-4" />
          <VuesaxBoldLinkSquare1 className="vuesax-bold-link-5" />
          <VuesaxBoldEdit28 className="vuesax-bold-edit" />
          <VuesaxBoldEdit28 className="vuesax-bold-edit-2-10" />
          <VuesaxBoldEdit28 className="vuesax-bold-edit-2-8" />
          <VuesaxBoldEdit28 className="vuesax-bold-edit-2-11" />
          <VuesaxBoldEdit28 className="vuesax-bold-edit-2-13" />
          <VuesaxBoldEdit28 className="vuesax-bold-edit-2-10-instance" />
          <VuesaxBoldEdit28 className="vuesax-bold-edit-2-8-instance" />
          <VuesaxBoldTickSquare1 className="vuesax-bold-tick" />
          <VuesaxBoldTickSquare1 className="vuesax-bold-tick-square-1" />
        </div>
        <div className="overlap-11">
          <VuesaxBoldGallery13 className="vuesax-bold-gallery-13" />
        </div>
      </div>
    </div>
  );
};
