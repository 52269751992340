/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Size48 = ({ className }) => {
  return (
    <svg
      className={`size-48 ${className}`}
      fill="none"
      height="34"
      viewBox="0 0 24 34"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path-101"
        d="M12 26.9168V7.0835M12 7.0835L5 17.0002M12 7.0835L19 17.0002"
        stroke="#1E1E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </svg>
  );
};
