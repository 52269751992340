/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const NameCloseTypeFill = ({ color = "#282A37", stroke = "white", className }) => {
  return (
    <svg
      className={`name-close-type-fill ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill={color} height="24" rx="12" width="24" />
      <path className="path-101" d="M8.25 8.25L15.75 15.75" stroke={stroke} strokeLinecap="round" strokeWidth="1.125" />
      <path
        className="path-101"
        d="M15.75 8.25L8.25001 15.75"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="1.125"
      />
    </svg>
  );
};

NameCloseTypeFill.propTypes = {
  color: PropTypes.string,
  stroke: PropTypes.string,
};
