/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../Button";
import "./style.css";

export const ButtonGroup = ({ buttonEnd = true, buttonStart = true, align, className, buttonLabel = "Button", to }) => {
  return (
    <Link className={`button-group ${align} ${className}`} to={to}>
      {buttonStart && (
        <Button
          className={`${align === "justify" ? "class" : align === "stack" ? "class-2" : "class-3"}`}
          label="Button"
          size="medium"
          stateProp="default"
          variant="subtle"
        />
      )}

      {buttonEnd && (
        <Button
          className={`${align === "justify" ? "class" : align === "stack" ? "class-2" : "class-3"}`}
          label={buttonLabel}
          size="medium"
          stateProp="default"
          variant="primary"
        />
      )}
    </Link>
  );
};

ButtonGroup.propTypes = {
  buttonEnd: PropTypes.bool,
  buttonStart: PropTypes.bool,
  align: PropTypes.oneOf(["start", "center", "justify", "stack", "end"]),
  buttonLabel: PropTypes.string,
  to: PropTypes.string,
};
