
  import React, { useState, useEffect, useRef } from 'react';
  import { Link } from "react-router-dom";
  import { VuesaxBoldEdit } from "../../components/VuesaxBoldEdit";
  import { VuesaxBoldLogoutWrapper } from "../../components/VuesaxBoldLogoutWrapper";
  import { VuesaxBoldDiscover1 } from "../../icons/VuesaxBoldDiscover1";
  import { VuesaxBoldElement31 } from "../../icons/VuesaxBoldElement31";
  import { VuesaxBoldGallery13 } from "../../icons/VuesaxBoldGallery13";
  import { VuesaxBoldLinkSquare1 } from "../../icons/VuesaxBoldLinkSquare1";
  import { VuesaxBoldTickSquare1 } from "../../icons/VuesaxBoldTickSquare1";
  import { VuesaxLinearMinusSquare2 } from "../../icons/VuesaxLinearMinusSquare2";
  import { VuesaxLinearSearchNormal1 } from "../../icons/VuesaxLinearSearchNormal1";
  import { fetchEpisodes } from '../../screens/Index/index.js';
  import "./style.css";
  import mysql from 'mysql';
  import { FaTrash } from 'react-icons/fa';
  import ftp from 'ftp';
  import Popup from 'reactjs-popup';
  import 'reactjs-popup/dist/index.css'
  import axios from 'axios';
  import '../Episodios/upload.css';
  const fileUpload = require('express-fileupload');
  import 'reactjs-popup/dist/index.css';
  var mysql = require('mysql');

  var con = mysql.createConnection({
    host: "localhost",
    user: "admin",
    password: "admin",
    database: "podcast_db"
  });

  export const Episodios = () => {
    let cssThumbnail = 1;
    let cssNome = 164;
    let cssDuracao = 171;
    let cssData = 147;
    let cssStatus = 154;
    let cssEditDelete = 12;

    const [playlists, setPlaylists] = useState([]);

    useEffect(() => {
      const getEpisodes = async () => {
        try {
          const data = await fetchEpisodes();
          setPlaylists(data);
        } catch (error) {
          console.error('Erro ao buscar episódios:', error);
        }
      };

      getEpisodes();
    }, []);

    // Transforma a data atual em uma string formatada
    let diaSemana = new Date().getDay();
    const dia = new Date().getDate();
    const mes = new Date().getMonth();
    const ano = new Date().getFullYear();

    let dias = [
      "domingo",
      "segunda-feira",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado"
    ];

    let meses = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro"
    ];
    let apresentacao = `${dias[diaSemana]}, ${dia} de ${meses[mes]} de ${ano}`;
    console.log("deded");

    let [selectedFile, setSelectedFile] = useState(null);
    let [selectedFileThumb, setSelectedFileThumb] = useState(null);
    const fileInputRef = useRef(null);
    const fileInputRefThumb = useRef(null);
    const [text, setText] = useState('');
    const [textDescricao, setTextDescricao] = useState('');

    const handleChange = (event) => {
      setText(event.target.value); // Atualiza o estado com o valor do textarea
    };
    const handleChange2 = (event) => {
      setTextDescricao(event.target.value); // Atualiza o estado com o valor do textarea
    };

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };
    const handleFileChangeThumb = (event) => {
      setSelectedFileThumb(event.target.files[0]);
    };

    const handleLinkClick = () => {
      fileInputRef.current.click();
    };
    const handleLinkClick2 = () => {
      fileInputRefThumb.current.click();
    };

    const handleDelete = async (id) => {
      try {

        const response = await axios.delete(`http://localhost:3005/api/episodes/${id}`);
        console.log(response.data);
        window.location.reload();
      } catch (error) {
        console.error('There was an error deleting the record!', error);
      }
    };

    // Manipular o arquivo selecionado
    const handleFileSelect = async (event) => {
      let file = selectedFile;
      let fileThumb = selectedFileThumb;
      let formData = new FormData();
      formData.append('file', file);
      

      try {
        const response = await axios.post('http://localhost:3001/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Upload successful:', response.data);

        console.log('Episode inserted into database:', dbResponse.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
      if (fileThumb) {
      formData = new FormData();
      formData.append('file', fileThumb);
      try {
        const response = await axios.post('http://localhost:3001/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Upload successful:', response.data);

        console.log('Episode inserted into database:', dbResponse.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

      
    

      const dbResponse = {
        podcast_id: 1,
        title: text,
        description: textDescricao,
        duration: 20,
        audio_url: `https://ftp.saudementalemcampo.ngrok.app/audio?filename=${file.name}`,
        release_date: `${ano}-${mes + 1}-${dia}`,
        thumb_url: `https://ftp.saudementalemcampo.ngrok.app/audio?filename=${fileThumb.name}`,
        
      };
  
      try {
        const response = await axios.post('http://localhost:3005/api/episodes', dbResponse);
        console.log(response.data);
        window.location.reload();
      } catch (error) {
        console.error('There was an error inserting the record!', error);
      }
    };

      
    


    

    return (
      <div className="episodios">
        <div className="div-8">
          <div className="group-21">
            <div className="group-22">
              <div className="text-wrapper-140">Bem vindo, Enrico</div>
              <p className="text-wrapper-141">{apresentacao}</p>
            </div>
          </div>
          <div className="group-23">
            <div className="rectangle-8" />
            <VuesaxLinearSearchNormal1 className="vuesax-linear-search-normal-1-instance" />
            <div className="text-wrapper-142">Buscar</div>
          </div>
          <img className="vector-20" alt="Vector" src="/img/vector-11.svg" />
          <div className="group-24">
            <Link className="group-25" to="/index">
              <div className="rectangle-9" />
              <VuesaxBoldLogoutWrapper />
            </Link>
            <div className="text-wrapper-143">Menu</div>
            <VuesaxBoldElement31 className="vuesax-bold-element-3-2" color="#18A0FB" />
           {/* <VuesaxBoldDiscover1 className="vuesax-bold-discover-1" /> */}
          </div>

          <div className="group-26 ">
            <div />
            <Link className="file-input">Novo episódio</Link>

            <Popup  trigger= 
                {<button className="upload-btn-4"> Novo episódio</button>} 
                
                modal nested className="group-26 popup-content">
                {
                    close => (
                      <div className='upload-main-container'>
                      <div className='upload-section'>
                        <span className='upload-text'>Adicionar novo episódio</span>
                        <div className='upload-wrapper'>
                          <div className='upload-img' />
                        </div>
                      </div>
                      <div className='upload-group'>
                        <div className='upload-title-icon'>
                          <div className='upload-paper-upload'>
                            <div className='upload-iconly-outline-paper'>
                              <div className='upload-paper-upload-1'>
                                <div className='upload-combined-shape' />
                              </div>
                            </div>
                          </div>
                        
                          <div className='upload-title-subtitle'  >
                            <span className='upload-click-select-audio'>
                              Clique para selecionar o aúdio
                            </span>
                            <span className='upload-mp3-files-only'>Apenas arquivos .mp3</span>
                          </div>
                          <input
                            type="file"
                            accept=".mp3"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            
                           // style={{ display: 'none' }} // Oculta o input
                          />
                        </div>
                      </div>
                      <div className='upload-line'>
                        <div className='upload-vector' />
                       
                        <div className='upload-vector-2' />
                      </div>
                      <div className='upload-wrapper-2'>
                        <span className='upload-text-5'>Nome do episódio</span>
                        <div className='upload-group-3'>
                          <textarea id="textNome" value={text} onChange={handleChange} className='upload-text-6 upload-no-resize'  />
                        </div>
                      </div>
                      <div className='upload-wrapper-2'>
                        <span className='upload-text-5'>Descrição</span>
                        <div className='upload-group-3'>
                          <textarea value={textDescricao} onChange={handleChange2} placeholder="Adicione uma descrição ao episódio"  className='upload-text-6 upload-no-resize'  />
                        </div>
                      </div>
                      
                      <div className='upload-wrapper-2'>
                        <span className='upload-text-5'>Miniatura (Resolução 139 x 139)</span>
                        <div >
                        <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleFileChangeThumb}
                            ref={fileInputRefThumb}
                            
                           // style={{ display: 'none' }} // Oculta o input
                          />
                        </div>
                      </div>
                      
                      
                      <div className='upload-section-6'>
                        <div className='upload-group-4'>
                          <button className='upload-btn' >
                            <div className='upload-btn-3'>
                              <span className='upload-upload' onClick={handleFileSelect}>Upload</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                )}
            </Popup>
            
            

          </div>
          
          {/*<div className="group-27">
            <div className="rectangle-11" />
            <Link className="text-wrapper-145">Excluir episódio</Link>
          </div> */}
            
          <div className="rectangle-12" />
          <div className="text-wrapper-146">Thumbnail</div>
          <div className="text-wrapper-147">Nome</div>
          <div className="text-wrapper-162">ID</div>
          <div className="text-wrapper-179">Data publicado</div>
          <div className="text-wrapper-163">Status</div>
          {/* <div className="rectangle-20" /> */}
          {playlists.map((playlist, index) => (
            (cssThumbnail = cssThumbnail + 1),
            (cssNome = cssNome + 1),
            (cssDuracao = cssDuracao + 1),
            (cssData = cssData + 1),
            (cssStatus = cssStatus + 1),
            (cssEditDelete = cssEditDelete + 1),
            (thumbnail = `vuesax-bold-gallery-${cssThumbnail}`),
            (nome = `text-wrapper-${cssNome}`),
            (duracao = `text-wrapper-${cssDuracao}`),
            (data = `text-wrapper-${cssData}`),
            (status = `text-wrapper-${cssStatus}`),
            (edit = `vuesax-bold-link-${cssEditDelete}`),
            (Delete = `vuesax-bold-link-${cssEditDelete}-2`),
            <div key={index} className="playlist-item">
              {/* <VuesaxBoldGallery13 className={thumbnail} src={section.thumb_url || "/img/headphones.svg"} />*/}
              <img  className={thumbnail}  src={playlist.thumb_url || "/img/headphones.svg"} />
              <div className={nome}>{playlist.title}</div>
              <div className={duracao}>{playlist.id}</div>
              <div className={data}>{(playlist.release_date.substring(8, 10) + "/" + playlist.release_date.substring(5, 7) + "/" + playlist.release_date.substring(0, 4)) }</div>
              <div className={status}>Publicado</div>
          {/* <VuesaxBoldLinkSquare1 className={Delete} /> */}
              <Link className={edit} onClick={() => handleDelete(playlist.id)}>
              <FaTrash /> {/* Adiciona o ícone de lixeira */}
              </Link>
            </div>
          ))}
          
        </div>
      </div>
    );
  };
